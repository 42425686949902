
import router from "@/router";
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const goTo = (rota: string): void => {
      router.push({ name: rota });
    };

    return { goTo, store };
  },
});
